import React, { useEffect } from 'react';
import { Flex, Text, useMediaQuery, useTheme, Image } from '@chakra-ui/react';
import Slider from "react-slick";
import "../../styles/styled.css";
import ButtonModal from '../ButtonModal';

import './animation.css';

const Departamentos = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterSmThanMd] = useMediaQuery(`(min-width: ${breakpoints.sm})`);

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto1.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto2.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto3.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto4.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto5.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto6.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dptos/proto7.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/animacion4.svg' }
    ];

    function renderSize() {
        if (isGreaterThanMd) {
            return 3;
        }
        else if (isGreaterSmThanMd) {
            return 2;
        }
        else {
            return 1;
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: renderSize(),
        slidesToScroll: renderSize(),
        rows: 1,
        activeIndex: 0
    };

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-departamentos');
                } else {
                    entry.target.classList.remove('show-departamentos');
                }
            })
        })

        const hiddenElementsL = document.querySelectorAll('.hidden-left-departamentos');
        hiddenElementsL.forEach((el) => observerL.observe(el));

        const observerR = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-departamentos');
                } else {
                    entry.target.classList.remove('show-departamentos');
                }
            })
        })

        const hiddenElementsR = document.querySelectorAll('.hidden-right-departamentos');
        hiddenElementsR.forEach((el) => observerR.observe(el));
    }, []);

    return (
        <div className='sections-departamentos'>
            <div className='hidden-left-departamentos'>
                <Flex
                    flexDirection={"column"}
                    alignItems={"end"}
                    mt={20}
                    width={"100%"}
                >
                    <Flex textAlign={"center"} alignItems={"center"}
                        flexDirection={"column"} width='100%' marginBottom={"3rem"} pl={isGreaterThanMd ? "10%" : 0}>
                        <Text textTransform={"uppercase"} color={"#A0BA5F"} fontWeight={600} fontSize='49px'>
                            Espacios que conectan con tu esencia
                        </Text>
                        {isGreaterThanMd ?
                            <Text color={"#545454"} fontWeight={300} fontSize={'24px'} mb={2} mt={5}>
                                Departamentos de 2 recámaras en la zona más trendy de Zapopan, desde 71 m<sup>2</sup>.
                            </Text>
                            :
                            <Text color={"#545454"} fontWeight={300} fontSize={'20px'} mb={2} mt={5}>
                                Departamentos de 2 recámaras en la<br />zona más trendy de Zapopan, desde 71 m<sup>2</sup>.
                            </Text>
                        }
                    </Flex>
                    <Flex flexDirection={"column"} width={"90%"} pr={"4%"}>
                        <Slider {...settings}>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[0].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[1].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[2].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[3].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[4].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[5].imageUrl} alt='icon' />
                            </div>
                            <div>
                                <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={icons[6].imageUrl} alt='icon' />
                            </div>
                        </Slider>
                    </Flex>
                    <Flex flexDirection={"column"} width={"100%"} mt={10} marginBottom={"3rem"} alignItems={"center"} pl={isGreaterThanMd ? "18%" : 0}>
                        <ButtonModal
                            idForm={"5ebea09d-c278-410b-a3da-32e464f60d36"}
                            thankYouPage={"thankyoupage-contactar-whatsapp"}
                            //thankYouPage={"thankyoupage-agenda-cita"}
                            isImage={false}
                            color='#80A32A'
                            bg='#FFF'
                            bgHover='#80A32A'
                            colorHover='#FFF'
                            border='2px solid #80A32A'
                            srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/redes-sociales/wa.svg'}
                            title={'Envíanos un Whatsapp'}
                            title_form={'form_whatsapp'}
                        />
                    </Flex>
                    <Flex width={"100%"} justifyContent={"center"} mb={10} pl={isGreaterThanMd ? "18%" : 0}>
                        <Text color={"#E69730"} fontSize={"49px"} fontWeight={600} textTransform={"uppercase"}>
                            Planes de pago
                        </Text>
                    </Flex>
                </Flex>
            </div>
            <div className='hidden-right-departamentos'>
                <div className='wrapper-img-departamentos'>
                    <img id='animacion4' src={icons[7].imageUrl} width="150" height="250" alt='icon scroll' />
                </div>
            </div>
        </div>
    );
}

export default Departamentos;