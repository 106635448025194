import React, { useState, useEffect } from 'react';
import { 
    Flex, 
    Text,  
    useMediaQuery, 
    useTheme,
    Image,
    Box,
    useDisclosure
} from '@chakra-ui/react';

import "../styles/styled.css";
import "../styles/presentacion.css";
import Nav from './Nav';

import { MarkDownSection } from './Section';
import { Carousel } from './Carousel/Carousel';
import ModalCard from './ModalCard';

const Presentacion = (props) => {
    const [current, setCurrent] = useState(0);
    const [dotClicked, setDotClicked] = useState(false);
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const { isOpen: isOpenContact, onOpen: onOpenContact, onClose: onCloseContact} = useDisclosure();
    const [screenSize, getDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    
    const setDimensions = () => {
        getDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/description.svg' }
    ];

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/banner1.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/banner2.png' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/banner3.png' }
    ];

    const slides = [
        {
            key: "hero1",
            imageUrl: images[0].imageUrl,
            title: "Tu primer inversión para rentas<br/>de corta estancia",
            bg: "#E69730"
        },
        {
            key: "hero2",
            imageUrl: images[1].imageUrl,
            title: "Tu propio depa en el centro de todo",
            bg: "#C80A7F"
        },
        {
            key: "hero3",
            imageUrl: images[2].imageUrl,
            title: "Tu vida en un lugar icónico",
            bg: "#93C020"
        }
    ];

    useEffect(() => {
        if (!dotClicked) {
          const intervalId = setInterval(() => {
            if (current === slides.length-1) {
              setCurrent(0);
            } else {
              setCurrent(current + 1);
            }
          }, 5000);
        
          return () => clearInterval(intervalId);
        }
    }, [dotClicked, current, slides.length]);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
    
        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, [screenSize]);

    return ( 
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            <Box id='proyecto' w="full" mx="auto" maxW="3x1" {...props} borderRadius={"8px"} padding={"1rem"}  position="relative">
                <Carousel current={current}>
                    { slides.map((slide, idx) => (
                        <Flex
                            w="100%"
                            h="100vh"
                            key={idx}
                            id="fondo"
                            backgroundImage={`url(${slide.imageUrl})`}
                            backgroundSize="cover"
                            backgroundPosition="center center"
                            backgroundRepeat="no-repeat"
                            backgroundColor="gray.100"
                            position="relative"
                            p="0"
                            color={"#FFFFFF"}
                            borderRadius="10px">
                            <Flex borderRadius="18px" height='100%' w='100%' backgroundColor={"#0000002e"}></Flex>
                            <Flex
                                alignItems={"initial"}
                                height="100vh"
                                position="absolute"
                                top={"20"}
                                left={isGreaterThanMd ? "16" : "0"}>
                                <Flex textTransform={"uppercase"} flexDirection="column" paddingRight={isGreaterThanMd ? 10 : 0} alignItems={isGreaterThanMd ? "initial" : "center"}>
                                    <Text textAlign={isGreaterThanMd ? "initial" : "center"} fontFamily={'ResistSansText'} fontWeight={300} fontSize={isGreaterThanMd ? '40px' : '4xl'}>
                                        <MarkDownSection>{slide.title}</MarkDownSection>
                                    </Text>
                                    <Image src={icons[0].imageUrl} style={{ width: isGreaterThanMd ? "80%" : "90%", height: isGreaterThanMd ? "23px" : "50px" }} alt='description'/>
                                    <Flex mt={'2rem'} width={"max-content"} bg={'transparent'} padding={"0.1rem 0rem"}>
                                        <Text fontWeight={700} fontSize={isGreaterThanMd?'23px':'15px'} maxW={'100%'} textAlign={isGreaterThanMd?'left':'center'}>Departamentos en preventa desde $3.2 MDP*</Text>
                                    </Flex> 
                                    {isGreaterThanMd ?
                                        <Flex mt={'2rem'} width={"max-content"} bg={slide.bg} padding={"0.5rem 3rem"} onClick={onOpenContact} zIndex={999} cursor={'pointer'}>
                                            <Text fontWeight={700} fontSize='23px' cursor={'pointer'}>Quiero ser contactado</Text>
                                        </Flex> : null
                                    }
                                </Flex>
                            </Flex>
                            <Flex
                                justifyContent={isGreaterThanMd ? "initial" : "center"}
                                alignItems={"end"}
                                height="100vh"
                                width={"100%"}
                                position="absolute"
                                bottom={isGreaterThanMd ? "10" : "20"}
                                textAlign={"center"}
                                left={isGreaterThanMd ? "16" : "0"}>
                                <Text fontWeight={500} fontSize='16px'>*Precios sujetos a cambios sin previo aviso</Text>
                            </Flex>
                            {!isGreaterThanMd ?
                                <Flex
                                    justifyContent={"center"}
                                    alignItems={"end"}
                                    height="100vh"
                                    width={"100%"}
                                    position="absolute"
                                    color={"#FFF"}
                                    zIndex={1}
                                    pb={'10px'}
                                    textAlign={"center"}
                                    onClick={onOpenContact} >
                                    <Flex borderRadius={"11px"} bg={slide.bg} width={"80%"} justifyContent={"center"} p={"0.5rem"}  zIndex={999} cursor={'pointer'}>
                                        <Text fontWeight={700} fontSize='16px' cursor={'pointer'} zIndex={999}>QUIERO SER CONTACTADO<br /></Text>
                                    </Flex>
                                </Flex> : null
                            }
                            {!isGreaterThanMd ?
                                <Flex
                                    justifyContent={"center"}
                                    alignItems={"end"}
                                    height="100vh"
                                    width={"100%"}
                                    position="absolute"
                                    color={"#FFF"}
                                    zIndex={1}
                                    textAlign={"center"}
                                    onClick={onOpenContact}>
                                    
                                </Flex> : null
                            }
                        </Flex>
                    ))}
                </Carousel>
                <Flex
                    direction="column"
                    alignItems="end"
                    position="absolute"
                    bottom="50vh"
                    w="100%"
                    left="0"
                    right="0"
                    paddingRight={"1.5rem"}
                >
                    {slides.map((item, index) => (
                    <Text
                        key={`dot-${index}`}
                        onClick={()=> {
                        setDotClicked(!dotClicked);
                        setCurrent(index);
                        }}
                        marginRight={(index === current) ? "0px" : "2px"}
                        cursor="pointer"
                        fontSize={(index === current) ? "25px" : "20px"}
                        color={ (index === current) ? "#FFF" : "#FFF"}
                    >
                        &#9679;
                    </Text>
                    ))}
                </Flex>
            </Box>
            {
                isOpenContact && (
                    <ModalCard 
                        isOpen={isOpenContact}
                        onClose={onCloseContact}
                        title={'Quiero ser Contactado'}
                        facebook={false}
                        thankyoupage={'thankyoupage-contacto-gracias'}
                        idForm={'343dd616-ba22-45e5-a00b-2f6f7604e1ea'}
                    />
                )
            }
        </>
    );
}
 
export default Presentacion;